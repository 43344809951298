import { PrismicRichText } from "@prismicio/react";
import { graphql } from "gatsby";
import React from "react";
import IconContent from "../components/icon-content";
import PageHeader from "../components/page-header";
import Seo from "../components/seo";
import Layout from "../layouts/index";

const WorkingHere = ({ data }) => {
  if (!data) return null;

  const benefits = data.allPrismicEmployeeBenefit.nodes;
  return (
    <Layout>
      <Seo title="Your benefits" />

      <PageHeader title="Your benefits" />

      <div className="page-content">
        <section className=" grid-container narrow">
          <p>
            When you choose to build your career with MotoNovo Finance and the
            Aldermore Group, we choose to invest in you. Take a look at a
            snapshot of some of the benefits you could receive:
          </p>
        </section>
        {benefits.map((benefit, idx) => (
          <section
            key={idx}
            className="block block-small grid-container narrow"
            aria-label={benefit.data.title.text}
          >
            <h2>{benefit.data.title.text}</h2>
            <PrismicRichText field={benefit.data.content.richText} />
          </section>
        ))}
        <div className="grid-container narrow">
          <p>
            Some of our benefits are only available to on an annual or bi-annual
            basis. All employees have access to our My Deal Benefits Platform to
            understand at which points of the year benefits are available and
            any eligibility requirements.
          </p>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query WorkingHereQuery {
    allPrismicEmployeeBenefit(sort: { fields: data___order, order: DESC }) {
      nodes {
        data {
          title {
            text
          }
          content {
            richText
          }
          icon {
            gatsbyImageData
          }
        }
      }
    }
  }
`;

export default WorkingHere;
